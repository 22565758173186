 *{
   box-sizing: border-box;
 }
// html { height: 100%; font-size: 10px; } *, *::before, *::after { box-sizing: inherit; }




 .btn-circle.btn-md { 
            
            padding: 7px 10px; 
            border-radius: 25px; 
            font-size: 10px; 
            text-align: center; 
        } 
         
           .btn-circle.btn-sm { 
            width: 10px; 
            height: 10px; 
            padding: 6px 0px; 
            border-radius: 10px; 
            font-size: 10px; 
            
            text-align: center; 
        } 
       .municipality-name{
         color: grey;
         font-size: 12px;
         font-weight: 200;
       }
  .load{
      text-align: center;
       width: 1320px;
       margin-top: 320px;
  height: 50px;
  display: inline-block;
  }

 
  .loader {
  position: fixed;
  top: 50px;
  right: 50px;
  bottom: 0;
  left: 0;
  z-index: 9999;

  
}
  .loader p{
    position: relative;
  color: black;
  top: 54%;
right: 54%;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 0;
  
}
 
 
.country{
    text-align: center;
}
  
p{
  margin: 10px 20px;
 
font: Medium 21px/32px Montserrat;
letter-spacing: 0px;
color: #F0F0F0DE;
opacity: 1;
font-size: small;
line-height: 1.8;
}

 .radio-button{
   cursor: pointer;
    height: 15px;
  width: 15px;
 }



.bolder{

  height:10px;
  width:10px;
}

 .map{

   background-color: black;
  color: white;
 }

 
.spy {
   background-color: black;
  color:white;
  width: 200px;
  height: 200px;
  
  overflow-x: hidden;
  overflow-y: scroll;
}        

.abc{
  position: absolute;
  top: 0;
  right: 0;
  
}


  
.test{
 
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  background-color:#424d5c;
  color:grey;
 display: flex;
 flex-direction: column;
  z-index: 11;
  height: 100vh;
  width: 30vw;
  float: left;
  
 
  

}
.sarlahi-parent{
  position: relative;
  display: flex;
  width: 100%;
  float: left;
 

}
.but{
bottom: 0;
align-items: right;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #424d5c; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:grey; 
  border-radius: 10px;
}

.why{


  background-color:#424d5c;
  color:grey;  
  z-index: 12;
text-align: right;
padding-right: 10px;
display: flex;
align-items: space-between;
justify-content: space-between;


}
  .details{
    overflow:auto;
    background-color: #0f0f10;
    color: white;
    flex: 1;
    
  }
  
  .legend{

    
  position: absolute;
  background-color:#0f0f10 ;
  right: 0;
  padding-right: 10px;
  color: white;
  top:0;
  padding-top: 30px;
  font-size: 12px;
   width: 14rem;
 
  height: 35%;
 
  }

  .district-link{
    color:#FFFFFF;
    vertical-align: sub;
    background: #424d5c;
    border: none;
    &:hover{
      color: white;
      text-decoration: none;
    }
  }
  .map-border{
width: 100%;
float: left;
  }

.chart{
 margin-left: 20px;
 
}
.map-react{
  position: relative;
  width: 70vw;
  float: left;
  
}
.map-react1{
  position: relative;
  width: 70vw;
  float: left;
  
}
.lvl{
  justify-content: center;
  font-size: small;
  padding-bottom: 0.11px;
  opacity: 0.87;

}

.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #424d5c;
  cursor: pointer;
  font-size: 18px;
}

.active, .btn:hover {
  background-color:orange;
 
}

h2{
  margin: 10px 20px;
  padding-top: 20px;
  padding-bottom: 1px;
  //  font: Bold 34px/20px Montserrat;
 font: Bold  Montserrat;  
   
   
   letter-spacing: 0.01px;
   color: #F0F0F0DE;  
   opacity: 1;
  
}

.dot {
  height: 14px;
  width: 14px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
 opacity:0.75 ;
  
}

.but{
  background: none;
  background-color: #424d5c;
  border: none;

}
.dist-choose{
padding-right: 10px;
padding-top: 5px;
}

b{
  color:  orange;
}

.bulgy-radios {
  width: 38rem;
  padding: 0.001rem 0 3rem 0.001rem;
  border-radius: 1rem;
 


  
  
  
  label {
    display: block;
    position: relative;
    height: 1em;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    padding-top: 0.2rem;
    
  
   
    cursor: pointer;
    font-size: 0.8rem;
    user-select: none;
    color: #555;
    letter-spacing: 1px;
    &:hover input:not(:checked) ~ .radio {
      opacity: 0.8;
    }
  }
  .label {
    display: flex;
    align-items: center;
    padding-right: 3rem;
    padding-buttom:2rem;
    
    span {
      line-height: 1em;
    }
  }
  input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
    left: -2000px;

    &:checked {
      ~ .radio {
        background-color: #0ac07d;
        transition: background .3s;
        &::after {
          opacity: 1;
        }
      }
      ~ .label {
        color: orange;
        // span {
        //   animation: bulge 1.1s forwards;
        // }

        //adjust this if label is going to be more than 20 chars
        @for $i from 1 to 20 {
          span:nth-child(#{$i}) { animation-delay: $i * 0.025s; }
        }
      }
    }
  }
}
.radio {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 0.8rem;
  width: 0.8rem;
  background: #c9ded6;
  border-radius: 50%;
  // padding-top: 1rem;
  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 0rem;
    left: 0rem;
    width: 0.8rem;
    height: 0.8rem;
    border: 3px solid orange;
     box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
  }
}
@keyframes bulge {
  50% {
    transform: rotate(4deg);
    font-size: 1.5em;
    font-weight: bold;
  }
  100% {
    transform: rotate(0);
    font-size: 1em;
    font-weight: bold;
  }
}

.nav-control{
  width: 30px;
  margin-left: 10px;
  margin-top: 10px;
}

.heading1{
font-size: large;
font-weight: 400;
padding-top: 5px;
line-height: normal;
padding-bottom: 20px;

}




.bar{
  flex: 1;
  // margin-top: 120px;

}

// .covid-dist{
//   color: orange;
//   font-size: medium;
//   font-weight: 500;

  
// }


// .covid-data-count{
// margin-left: 20px;
// }

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding-left: 15px;
  color: white;
  font-weight: 400;
  font-size: medium;
  opacity: 0.67;


  
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  
  clear: both;
}

.head{
  margin-top: 20px;
  opacity: 0.87;
}


@media only screen and (max-width:500px) {
  .sarlahi-parent{
    overflow: scroll;
  }
  /* For mobile phones: */
  .test{
    width: 100%;
    
  }
 
  // .map-border {
  //   width: 100%;
    
  // }
 
 .map-react{
  position: relative;
  width: 100vw !important;
  float: left;
  height: 50vh;
  
}
 .map-react1{
  position: relative;
  width: 100vw !important;
  float: left;
  height: 50vh;
}
  .sarlahi-parent{
display: flex;
flex-direction: column;
    width:100%;
  }
  .legend{
  position: absolute;
  background-color:#0f0f10 ;
  left: 0;
  padding-left: 10px;
  color: white;
  top:0;
  padding-top: 30px;
  font-size: 12px;
   width: 14rem;
  height: 35%;
 
  }
  // .mapboxgl-canvas-container .mapboxgl-canvas{
  //   width: 100% !important;
  // }
 
}